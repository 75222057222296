//import 'alpinejs';
//console.log("JS LOADED")


import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});
console.log("JS LOADED")

// Get Form Field

//var Flickity = require('flickity');


import 'mmenu-js';
// Mmmenu Configuration
document.addEventListener(
  "DOMContentLoaded", () => {
    const menu = new Mmenu("#mobile-menu", {
                     "offCanvas": {
                  "position": "right"
               },
      extensions: ["position-left", "pagedim-black", "shadow-page", "theme-light", "position-front"],
      screenReader: {},
      navbars: [{
        "position": "top",
        "content": [
          "<div class='bg-white' style='padding: 30px; max-height: 300px; width: 60%; margin: 0 auto'><img style='max-width: 100%; max-height: 100%' src='/assets/logo-primary.svg'> </div>"
        ]
        },
        {
        "position": "bottom",
        "content": [
          "<div class='text-center'><a class='text-xl font-bold text-primary' href='mailto:info@weka.co.nz'>info@weka.co.nz</a><span class='px-2 opacity-25 text-primary'>|</span><a class='text-xl font-bold text-primary ' href='tel:0275250255'>027 525 0255</a></div>"
        ]
        }
      ],
    });
    const api = menu.API;

    document.querySelector("#mburger")
      .addEventListener(
        "click", (evnt) => {
          evnt.preventDefault();
          api.open();
        }
      );
  }
);


//import 'mhead-js';
/*
// Mhead Configuration
document.addEventListener(
  "DOMContentLoaded", () => {
    const menu = new Mhead( "#nav-primary", {
             head: 0
            });
        }
    );*/